import { rem, tint, shade } from 'polished'

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  green: '#009800',
}

const contextualColors = {
  primary: colors.green,
  secondary: colors.orange,
  primaryTitle: shade(0.25, colors.green),
  primaryText: tint(0.15, colors.black),
  dark: tint(0.15, colors.black),
  medium: tint(0.5, colors.black),
  mediumLight: tint(0.8, colors.black),
  light: tint(0.95, colors.black),
  celeste: '#e4e4ef',
  error: colors.red,
}

const theme = {
  typography: {
    fontFamily: `Open Sans, sans-serif`,
    fontSize: rem(14),
  },
  sizes: {
    sm: '100%',
    md: '1024px',
    lg: '1024px',
  },
  breakpoints: {
    sm: '640px',
    md: '1024px',
    lg: '1280px',
  },
  colors: {
    ...colors,
    ...contextualColors,
  },
}

export default theme
