import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'

import Root from '../Root'
import SEO from '../SEO'

const Wrapper = styled.main`
  position: relative;
`

const Layout = ({ title, description, author, origin, image, children }) => (
  <Root>
    <SEO {...{ title, description, author, image }} type="article" />
    <Wrapper>{children}</Wrapper>
  </Root>
)

export default Layout

export const Footer = styled.footer`
  position: relative;
  background-color: ${theme('colors.green')};
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 5rem;
  color: white;
  h2 {
    color: white;
    margin-bottom: 0.5rem;
  }
  a {
    color: white;
  }

  menu {
    display: flex;
    justify-content: center;
    padding: 0;
    > div {
      display: block;
      margin: 0 0.5rem;
      flex-grow: 0;
      flex-shrink: 0;
      &:first-child {
        width: 40%;
        text-align: right;
      }
      &:last-child {
        width: 40%;
        text-align: left;
      }
    }
  }
`
