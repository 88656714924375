import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'
import { normalize, rem } from 'polished'

export default createGlobalStyle`
  ${normalize()};

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  :root {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: ${theme('colors.primaryText')};
    overflow-x: hidden;
    background: ${theme('colors.celeste')};
    margin:0;
    padding:0;
  }

  body {
    margin: 0;
    padding: 0;
    text-align: center;
    overflow-x: hidden;
    background: ${theme('colors.celeste')};
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }

  h1 {
    font-family: 'Josefin Sans';
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.07rem;
    color: ${theme('colors.green')};
    margin: 0 0 .5rem 0;
  }

  h2 {
    font-family: 'Josefin Sans';
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: -0.07rem;
    color: ${theme('colors.green')};
    margin: 0 0 .5rem 0;
  }

  h3 {
    font-size: 1.5rem;
    font-family: 'Josefin Sans';
    font-weight: 500;
    color: ${theme('colors.green')};
    letter-spacing: -0.07rem;
    margin: 0 0 .2rem 0;
  }

  h4 {
    font-size: ${rem(18)};
    font-weight: 500;
    margin: 0;
  }

  p, li {
    margin: 0 0 .3rem 0;
    line-height: 1.5;
  }

  blockquote {
    font-style: italic;
    font-family: 'Amiri', sans-serif;
  }

  figcaption {
    font-style: italic;
  }

  strong {
    font-weight: 600;
  }

  address {
    font-style: normal;
  }

  a {
    color: ${theme('colors.primary')};
    // text-decoration: none;
    // :hover {
    //   text-decoration: underline;
    // }
  }
  ul {
    padding-left: 1.1rem;
  }
`
