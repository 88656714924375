import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from '../../theme'

const Root = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      {children}
    </>
  </ThemeProvider>
)

export default Root
